$("#login").on('click', "button[type='button']", function () {
    var usuario = $("#usuario").val();
    var password = $("#password").val();
    logear(usuario, password);
})
async function logear(usuario, password) {
    try {
        const respuesta = await login(usuario, password);
        if (respuesta == "ok") {
            swal.fire({
                icon: "success",
                title: "iniciando sesión...",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000
            }).then(function (result) {
                window.location = "inicio";
            });
        } else {
            swal.fire({
                icon: "error",
                title: "Datos incorrectos... Intente otra vez...",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 5000
            }).then(function (result) {
                return false;
            });
        }
    } catch (error) {
        return error;
    }
}
function login(usuario, password) {
    return new Promise(resolve => {
        var datos = new FormData();
        datos.append("usuario", usuario);
        datos.append("password", password);
        $.ajax({
            url: "ajax/login.ajax.php",
            method: "POST",
            data: datos,
            cache: false,
            contentType: false,
            processData: false,
            dataType: "json",
            success: function (respuesta) {
                resolve(respuesta);
            }
        });
    })
}